<template>
    <div class="hzhb">
        <ContactUs></ContactUs>
        <div class="hzhb_m">
            <div class="part_header">
                <router-link to="/">首页</router-link> > 合作伙伴
            </div>
            <div class="hzhb_content">
                <p>瞻世趣与彩票相关绝大多数的电子商务核心渠道均建立了合作关系，是市场上为数不多的能够协调彩票资源的企业</p>
                <h3>机构合作伙伴</h3>
                <p>体彩中心、福彩中心、香港赛马会</p>
                <h3>市场调研机构</h3>
                <p>AC尼尔森、零点调查</p>
                <h3>电商与门户</h3>
                <p>阿里、腾讯、新浪、百度、网易、乐视、360、京东等</p>
                <h3>支付渠道</h3>
                <p>支付宝、财付通、银联支付、手机钱包、易宝支付等</p>
                <h3>彩票专业平台</h3>
                <p>500彩票、澳客网、新浪彩通、彩票大赢家、爱彩网、必赢网、彩票宝、第一彩、爱波网、章鱼彩票、魔方元、竞彩258、竞彩猫、雷达体育等</p>
                <h3>彩票运营企业</h3>
                <p>高德中彩、中财公益源、黑龙江龙彩、英国BNN技术（原DJI）</p>
            </div>
        </div>
    </div>
</template>
<script>
    import ContactUs from "@/components/common/contactus.vue";
    export default {
        name: 'Gywm',
        components: {
            ContactUs
        }
    }
</script>
<style lang="less" scoped>
    .hzhb {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;

        .hzhb_m {
            width: 700px;
            margin-bottom: 100px;
            .hzhb_content{
                color: rgb(127, 127, 127);
                font-size: 16px;
                p {
                    line-height: 1.75em;
                }
                p:first-of-type{
                    text-indent: 2em;
                    line-height: 1.5;
                    margin-top: 25px;
                }
                h3 {
                    color: rgb(44, 90, 168);
                    margin-top: 15px;
                }
            }
        }
    }
</style>